const Loader = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div
        className="w-12 h-12 rounded-full animate-spin
        border-4 border-solid border-white-500 border-t-transparent"
      ></div>
    </div>
  );
};

export const ButtonLoader = () => {
  return (
    <div
      className="w-8 h-8 rounded-full animate-spin
        border-4 border-solid border-white-500 border-t-transparent"
    ></div>
  );
};

export default Loader;
