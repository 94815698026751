import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Loader from "./components/loader";
import { RouteType } from "./utils/interface";
import ROUTES from "./routes/routes";
import React from "react";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            {ROUTES.map((ele: RouteType, index: number) => (
              <Route path={ele.path} element={<ele.element />} key={index} />
            ))}
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
