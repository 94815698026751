import { lazy } from "react";
import { RouteType } from "../utils/interface";
const Home = lazy(() => import("../pages/home/home"));
const ContactUs = lazy(() => import("../pages/contact-us/contact-us"));
const GameScore = lazy(() => import("../pages/game-score/game-score"));
const Score = lazy(() => import("../pages/score/score"));

const ROUTES: RouteType[] = [
  {
    path: "/",
    element: Home,
  },
  {
    path: "/games-score",
    element: GameScore,
  },
  {
    path: "/games/:id",
    element: Score,
  },
  {
    path: "/contact-us",
    element: ContactUs,
  },
];

export default ROUTES;
